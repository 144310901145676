import Heading from "./components/Heading"
import InlineDropdown from "./components/InlineDropdown"
import HR from "./components/HR"
import DataPoint from "./components/DataPoint"
import Button from "./components/Button"
import Box from "./components/Box"
import ErrorBox from "./components/ErrorBox"

import { useEffect, useState } from 'react'

import dayjs from "dayjs"

export default function Track ({ datasets, className, children, uploadDataPoint }) {
    const [currentDataset, setCurrentDataset] = useState(Object.keys(datasets)[0])
    const [localDataPoint, setLocalDataPoint] = useState(null)
    const [allFieldsFilled, setAllFieldsFilled] = useState(false)
    
    // strictly UI
    const [showErrorBox, setShowErrorBox] = useState(false)
    const [disableButton, setDisableButton] = useState(false)
    
    useEffect(() => changeDataset(Object.keys(datasets)[0], true), [])
    
    const valueChanged = (field, val) => {        
        let temp = {}
        for(let key in localDataPoint) temp[key] = localDataPoint[key]
        temp[field] = val
        setLocalDataPoint(temp)
        
        let filled = true
        for(let key in temp) {
            if(temp[key] == null || temp[key] == ""){
                if(!datasets[currentDataset][key].disabled) filled = false
            } 
        }
        if(filled) {
            setAllFieldsFilled(true)
            setShowErrorBox(false)
            setDisableButton(false)
        }else{
            setShowErrorBox(true)
            setDisableButton(true)
            setAllFieldsFilled(false)
        }
    }
    
    const changeDataset = (newDataset, override=false) => {
        if(newDataset == currentDataset && !override) return
        
        // create actual data point (not template) to hold just the values
        let tempDataPoint = {}
        for(let key of Object.keys(datasets[newDataset])) tempDataPoint[key] = datasets[newDataset].default || null

        // add in current date and time
        tempDataPoint = DataPointWithDateAndTime(tempDataPoint)   
            
        setLocalDataPoint(tempDataPoint)
        setCurrentDataset(newDataset)
        setAllFieldsFilled(false)
    }
    
    const attemptDataPointUpload = () => {
        if(allFieldsFilled){
            uploadDataPoint(localDataPoint, currentDataset)
            setDisableButton(true)
        } else {
            setShowErrorBox(true)
            setDisableButton(true)
        }
    }
    
    return (
        <Box className="w-full md:w-[500px]">
            <Heading className="inline-block">Add Data Point to</Heading>
            <InlineDropdown options={Object.keys(datasets)} className="ml-2" optionSelected={({target}) => changeDataset(target.value)}/>
            
            <HR />
            <DataPoint template={datasets[currentDataset]} valueChanged={valueChanged}/>
            <HR />
            <Button onClick={attemptDataPointUpload} disabled={disableButton}>Save point</Button>
            
            { showErrorBox ? 
            <ErrorBox
                title="Empty Fields!"
                body="Please fill in all fields in order to save this data point."
            />
             : ''}
        </Box>
    )
}

const DataPointWithDateAndTime = (template) => {
    let date = dayjs().unix()
    let time = dayjs().format("HH:mm")
    time = parseInt(time.split(":")[0])*60 + parseInt(time.split(":")[1])
    
    if("date" in template) template.date = date
    if("time" in template) template.time = time
    
    return template
}