import Text from "./Text"
import { VscError } from 'react-icons/vsc'

export default function ErrorBox ({ className, warning=false, title, body, children }) {
    return (
        <div className={`flex flex-row p-2 my-4 bg-error-200 rounded border-2 border-error-300 items-center ${className}`}>
            <VscError 
                className="text-error-900 px-2"
                size={48}
            />
            <div>
                <Text className="font-semibold text-left text-error-900" colorOverride>{ title }</Text>
                <Text className="text-left text-error-900" colorOverride size={3}>{ body }</Text>
            </div>
        </div>
    )
}