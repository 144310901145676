import Heading from "./components/Heading"
import InlineDropdown from "./components/InlineDropdown"
import HR from "./components/HR"
import Box from "./components/Box"
import Text from "./components/Text"
import Loader from "./components/Loader"

import { useEffect, useState } from "react"

import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore'

import dayjs from "dayjs"

export default function Data ({ firestore, datasets, dataDocID, }) {
    const [currentDataset, setCurrentDataset] = useState(Object.keys(datasets)[0])
    const [pointsToShow, setPointsToShow] = useState(false)
    
    useEffect(() => getPointsFromSet(Object.keys(datasets)[0]), [])
    
    const getPointsFromSet = async (dataset) => {
        const datasetPath = `data/${dataDocID}/${dataset}`
        const datasetRef = collection(firestore, datasetPath)
        
        const q = query(datasetRef, orderBy("date"), limit(5))
        const docs = await getDocs(q)
        
        let points = []
        docs.forEach((doc) => {
            points.push(doc.data())
        });
        setPointsToShow(points)
    }
    
    const changeDataset = (newDataset) => {
        if(newDataset == currentDataset) return
        
        getPointsFromSet(newDataset)
        setCurrentDataset(newDataset)
    }
    return (
        <Box className="w-full md:w-[500px]">
            <Heading className="inline-block">Data for</Heading>
            <InlineDropdown options={Object.keys(datasets)} className="ml-2" optionSelected={({target}) => changeDataset(target.value)}/>
            <HR />
            
            { pointsToShow ? <DataPointList points={pointsToShow} /> : <Text>loading...</Text> }
        </Box>
    )
}

function DataPointList({points}) {
    return (
        <div>
            { points.map((val, i) => {
                return <ListItem point={val} key={i} />
            }) }
        </div>
    )
}
function ListItem({ point }){
    const time = dayjs(point.date).format("h:mm a") // ! This is giving the wrong time
    const date = dayjs(point.date).format("MM/DD/YY") // ! This is giving the wrong date
    
    return (
        <div className="flex flex-row bg-gray-100 shadow rounded-2xl p-4 justify-between items-center mb-2">
            <Text className="font-semibold">{ date } { time }</Text>
            <div className="text-left">
                { Object.entries(point).map(([key, val], i) => {
                    if(val == "" || val == null) return // don't show an empty field
                    if (key != 'date' && key != 'time'){
                        return <Text key={i}><span className="font-semibold">{key}:</span> {val}</Text>
                    }
                }) }
            </div>
        </div>
    )
}