export default function TextField ({ value, className, children, onValueChanged, }) {
    return (
        <span 
            contentEditable
            className="cursor-text text-gray-700 px-1 text-lg border-b-2 
            outline-none border-gray-200 focus:border-primary-700 
            w-24"
            onBlur={(e) => onValueChanged(e.target.textContent != "" ? parseInt(e.target.textContent) : "")}
            suppressContentEditableWarning={true}
        >
            { value }
        </span>

    )
}