import Tabs from "./components/Tabs";
import Heading from "./components/Heading";
import Track from "./Track";
import Box from "./components/Box";
import Text from "./components/Text";
import TextButton from "./components/TextButton";

import GoogleButton from 'react-google-button'
// import NotificationScheduler from "./components/NotificationScheduler";

import { useState, useEffect } from 'react'

import { initializeApp } from '@firebase/app'
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth'
import { getFirestore, doc, getDoc, addDoc, collection } from 'firebase/firestore'
// import { getMessaging, getToken, onMessage } from 'firebase/messaging'

import { useAuthState } from 'react-firebase-hooks/auth'
import Loader from "./components/Loader";
import Data from "./Data";
import Button from "./components/Button";

const tabs = ["track", "look", "account"]

const app = initializeApp({
    apiKey: "AIzaSyCmSJet1viPN24LAiidEqqbN-yGd-lTaoc",
    authDomain: "habitio-5ff68.firebaseapp.com",
    projectId: "habitio-5ff68",
    storageBucket: "habitio-5ff68.appspot.com",
    messagingSenderId: "95909969039",
    appId: "1:95909969039:web:ff74ada70feeb906a244fa",
    measurementId: "G-GVLMJGLKRP"
})

const auth = getAuth()
const firestore = getFirestore()
// const messaging = getMessaging()
// onMessage(messaging, (payload) => {
//     console.log('#####Message recieved#####', payload)
// })

function App() {
    const [user] = useAuthState(auth)
    const [userData, setUserData] = useState(false)

    // getMessagingToken(messaging, setTokenFound)

    useEffect(async () => {
        if (user) {
            const userDocRef = doc(firestore, 'users', user.uid)
            const userDoc = await getDoc(userDocRef)
            setUserData(userDoc.data())
        }
    }, [user])

    const uploadDataPoint = async (dp, ds) => {
        const datasetCollectionPath = `data/${userData.dataDocID}/${ds}`

        const dpDocRef = await addDoc(collection(firestore, datasetCollectionPath), dp)
        console.log("Document was written with ID: ", dpDocRef.id)
    }

    return (
        <div className="flex justify-start flex-col items-center h-screen bg-primary-700">
            {/* {userData && <NotificationScheduler notificationSchedule={userData.notifications} />} */}
            {
                user ?
                    (userData ?
                        ( // logged in and userData has loaded -> app
                            <>
                                {/* <Greeting name={user.displayName} /> */}
                                <Tabs titles={tabs} className="">
                                    <Track
                                        uploadDataPoint={uploadDataPoint}
                                        datasets={userData.datasets}
                                    />
                                    <Data
                                        datasets={userData.datasets}
                                        firestore={firestore}
                                        dataDocID={userData.dataDocID}
                                    />
                                    <Box><Heading>settings</Heading></Box>
                                </Tabs>
                                <TextButton inverse className="absolute right-4 bottom-4" onClick={() => signOut(auth)}>
                                    Sign Out
                                </TextButton>
                                <Box className="mt-16">
                                    {Notification.permission === 'granted' && <Heading> Notification permission enabled 👍🏻 </Heading>}
                                    {Notification.permission === 'default' && <Heading> Need notification permission ❗️ </Heading>}
                                    {Notification.permission === 'denied' && <Heading> Notification permission denied 😥 </Heading>}
                                    <ListOfCurrentNotifications />
                                </Box>
                            </>
                        ) : <Loader />) : // logged in but waiting for userData -> Loader
                    (
                        <SignIn /> // logged out -> SignIn button
                    )
            }
        </div>
    );
}

function SignIn() {

    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider()
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error("ERROR @ SIGNING IN WITH GOOGLE: ", errorMessage)
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
            })
    }

    return (
        <GoogleButton
            onClick={signInWithGoogle}
        />
    )
}
function Greeting({ name }) {
    return (
        <Heading inverse className="mb-16">Howdy, {name}.</Heading>
    )
}

// This is just for debugging
function ListOfCurrentNotifications() {
    const [notifications, setNotifications] = useState(false)
    const [showList, setShowList] = useState(false)

    
    const grabNotifications = async () => {
        const reg = await navigator.serviceWorker.getRegistration();
        const _notifications = await reg.getNotifications({
            includeTriggered: true
        });
        setNotifications(_notifications)
    }
    
    const clearNotifications = async () => {
        const reg = await navigator.serviceWorker.getRegistration();
        const _notifications = await reg.getNotifications({
            includeTriggered: true
        });
        notifications.forEach(notification => notification.close());
        grabNotifications()
    }
    
    useEffect(grabNotifications, [])
    
    return (
        <div>
            <TextButton className="inline-block mr-2" onClick={() => setShowList(!showList)}>{ showList ? 'Hide list' : 'Show list' }</TextButton>
            <TextButton className="inline-block ml-2" onClick={clearNotifications}>Clear all notifications</TextButton>
            {(notifications && showList) && notifications.map((notification, i) => {
                return (
                    <div className="" key={i}>
                        <Text size={3}><strong>{notification.title}</strong> - {notification.body}</Text>
                    </div>
                )
            })}
        </div>
    )
}

// export const getMessagingToken = (messaging, setTokenFound) => {
//     return getToken(messaging, { vapidKey: 'BPvRytwX4Hp8fs5X0qgtxyiBMBY6T4uDwnyXyVjXt_ZXlt_Phc_QC-RGyfAj_SUkG1wPsVVoXi8zSMM3yTBtTUw' }).then((currentToken) => {
//         if (currentToken) {
//             console.log('current token for client: ', currentToken);
//             setTokenFound(true);
//             // Track the token -> client mapping, by sending to backend server
//             // show on the UI that permission is secured
//         } else {
//             console.log('No registration token available. Request permission to generate one.');
//             setTokenFound(false);
//             // shows on the UI that permission is required 
//         }
//     }).catch((err) => {
//         console.log('An error occurred while retrieving token. ', err);
//         // catch error while creating client token
//     });
// }

export default App;
