import NumberField from './editable_fields/NumberField'
import BooleanField from './editable_fields/BooleanField'
import TextField from './editable_fields/TextField'
import Text from "./Text"
import { useState } from "react"

import dayjs from "dayjs"

export default function ({ template, className, children, valueChanged }) {   
    
    let fields = ConvertTemplateDictToArray(template)
    
    return (
        <div className="flex flex-col">
            { fields.map(([field, val], i) => {
                return (
                <Field 
                    field={field} 
                    type={val.type} 
                    unit={val.unitsAbr || false} // Abreviated units
                    value={false} 
                    key={i} 
                    onValueChanged={(_val) => valueChanged(field, _val)} />
                )
            }) }
        </div>
    )
}
function Field ({ type, field, value, unit, editable, onValueChanged }) {
    let editableField
    switch(type){
        case "number":
            editableField = (
                <div className="flex flex-row ">
                    <NumberField value={value} onValueChanged={onValueChanged}/>
                    
                    { unit ? 
                    <Text className="bg-gray-100 py-1 px-2 ml-2" size={3}>{ unit }</Text> : '' }
                </div>
            )
            break;
        case "text":
            editableField = (
                <div className="flex flex-row ">
                    <TextField value={value} onValueChanged={onValueChanged}/>
                    
                    { unit ? 
                    <Text className="bg-gray-100 py-1 px-2 ml-2" size={3}>{ unit }</Text> : '' }
                </div>
            )
            break;
        case "boolean":
            editableField = <BooleanField value={value} onValueChanged={onValueChanged} />
            break;
        case "date":
            editableField = <DateField />
            break;
        case "time":
            editableField = <TimeField />
            break;
    }
    
    return(
        <div className="flex flex-row justify-between py-3">
            <Text className="font-bold">{ field }</Text>
            { editableField }
        </div>
    )
}
function DateField() {
    let date = dayjs().format("MM/DD/YY")
    
    return (
        <Text className="px-1" size={2}>{ date }</Text>
    )
}
function TimeField() {
    let time = dayjs().format("hh:mm a")
    
    return (
        <Text className="px-1" size={2}>{ time }</Text>
    )
}
function ConvertTemplateDictToArray(templateDict){
    let array = Object.entries(templateDict)
    array.sort((a, b) => { return a[1].position - b[1].position })
    
    return array
}