import Text from "./Text"
import { useState } from 'react'

export default function ({ titles, className, children }) {
    const [activeTab, setActiveTab] = useState(0)
        
    return(
        <div className={` ${className}`}>
            <div className="flex flex-row my-32 justify-center">
                { titles.map((val, i) => {
                    return <TabSelector active={i==activeTab} title={val} key={i} onClick={() => setActiveTab(i)}/>
                }) }
            </div>
            <div>
                { 
                    children[activeTab]
                }
            </div>
        </div>
    )
}

function TabSelector({ title, active=false, onClick }) {
    return (
        <p 
            className={`text-2xl select-none px-4 border-b-2 cursor-pointer 
                ${active ? 'text-accent-600 border-accent-600' : 'text-gray-200 border-gray-200'}`}
            onClick={onClick}    
        >
            { title }
        </p>
    )
}