
export default function ({ className, children, onClick, disabled=false }) {
    return (
        <button 
            className={`text-white px-6 py-2 bg-primary-700 outline-none border-0
                transition-all transform ${disabled ? 'grayscale cursor-not-allowed' : 'grayscale-0 cursor-pointer focus:scale-90'} ${className}`}
            onClick={() => { if(!disabled) onClick() }}
        >
            { children }
        </button>
    )
}