export default function ({ options, className, children, optionSelected }) {
    return (
        <select 
            className={`cursor-pointer text-xl outline-none bg-gray-100 px-1 py-2 ${className}`}
            onChange={optionSelected}
        >
            { options.map((val, i) => {
                return <option value={val} key={i}>{val}</option>
            }) }
        </select>
    )
}