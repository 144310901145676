import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import * as serviceWorker from '../public/service-worker'


ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
// serviceWorker.register()

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js')
}