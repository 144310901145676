export default function NumberField ({ value, className, children, onValueChanged, }) {
    return (
        <span 
            contentEditable
            className="cursor-text text-gray-700 px-1 text-lg border-b-2 
            outline-none w-auto border-gray-200 focus:border-primary-700 
            min-w-1"
            onBlur={(e) => onValueChanged(e.target.textContent != "" ? parseInt(e.target.textContent) : "")}
            suppressContentEditableWarning={true}
        >
            { value }
        </span>

    )
}